import "./style/global.css";
import "./style/slide.css";
import "./style/header.css";
import "./style/footer.css";

import Siema from "siema";

new Siema();

let containerBox = document.querySelector("#header-slide"),
  container = document.querySelector(".Header.box"),
  selector = container.querySelector(".slides"),
  length = selector.dataset.length;

containerBox.style.display = "block";

let slide = new Siema({
  selector,
  duration: 200,
  easing: "ease-out",
  perPage:
    window.innerWidth > 1080
      ? length > 5
        ? 5.5
        : length
      : window.innerWidth > 729
      ? 3.5
      : 1.5,
  startIndex: 0,
  draggable: true,
  multipleDrag: true,
  threshold: 20,
  loop: false,
  rtl: false,
});

const productsSlide = new Siema({
  selector: ".destacados_productos .products",
  duration: 200,
  easing: "ease-out",
  perPage:
    window.innerWidth > 1080
      ? length > 5
        ? 5.5
        : length
      : window.innerWidth > 729
      ? 3
      : 1,
  startIndex: 0,
  draggable: true,
  multipleDrag: true,
  threshold: 20,
  loop: false,
  rtl: false,
});

document.querySelectorAll(".destacados_productos .product").forEach((el) => {
  el.addEventListener("click", (ev) => {
    if (ev.target.tagName != "A") {
      ev.preventDefault();
      ev.stopPropagation();
    }
  });
});

container.querySelectorAll("[data-slide]").forEach((item) => {
  item.addEventListener("click", () => {
    let move = item.dataset.slide;
    move === "<" ? slide.prev() : move === ">" ? slide.next() : slide.to(move);
  });
});

document
  .querySelectorAll(".destacados_productos [data-slide]")
  .forEach((item) => {
    item.addEventListener("click", () => {
      productsSlide[item.dataset.slide === "<" ? "prev" : "next"]();
    });
  });

setInterval(() => productsSlide.next(), 10000);
